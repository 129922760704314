import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "staking"
};
const _hoisted_2 = ["innerHTML"];
import Tabbar from '@/components/Tabbar/Tabbar.vue';
import { lpAbout } from '@/http';
import { ref } from 'vue';
export default {
  __name: 'liquidity',
  setup(__props) {
    const lpAboutHtml = ref('');
    const _lpAbout = async () => {
      const {
        data
      } = await lpAbout();
      lpAboutHtml.value = data.info;
    };
    _lpAbout();
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        title: _ctx.$t('staking_liquidity_pool'),
        placeholder: "",
        fixed: ""
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "staking-content",
        innerHTML: lpAboutHtml.value
      }, null, 8, _hoisted_2), _createVNode(_component_van_button, {
        color: "#FC72FF",
        block: "",
        round: "",
        to: "/liquidity_pool"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('participate_in_staking')), 1)]),
        _: 1
      })]), _createVNode(Tabbar, {
        active: 1
      })], 64);
    };
  }
};